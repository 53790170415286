.custom-carousel-control {
    top: 50%;
    transform: translateY(-50%);
    background: gray;
    width: auto;
    bottom: unset;
}
.carousel-indicator-image{
    border:0.5px solid gray;
}
.carousel-indicator-image.active {
    border:2px solid gray;
}
.carousel-item-img-video{
}
.image-gallery .carousel-inner {
    /* width: calc(100% - 50px);
    left: 50%;
    transform: translateX(-50%); */
}